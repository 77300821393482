.label {
  margin-bottom: -1.2rem;
}
.q {
  width: 22px !Important;
  margin-right: 10px;
  height: 22px !Important;
  min-width: 0px !Important;
  background: linear-gradient(#ffffff, #000000);
  border-color: #000000;
  border-radius: 50px;
}

.q span {
  min-width: 100%;
  margin-left: 56px;
  margin-right: 10px;
}

.AGUARDANDO_SENHA {
  margin-right: 10px;
  width: 22px !Important;
  height: 22px !Important;
  min-width: 0px !Important;
  background: linear-gradient(#ffffff, #ffcc00);
  border-color: #ffcc00;
  border-radius: 50px;
}

.AGUARDANDO_SENHA span {
  margin-right: 10px;
  min-width: 100%;
  margin-left: 56px;
}

.AGUARDANDO_LIBERACAO_OPERADORA {
  margin-right: 10px;
  width: 22px !Important;
  height: 22px !Important;
  min-width: 0px !Important;
  background: linear-gradient(#ffffff, #808000);
  border-color: #808000;
  border-radius: 50px;
}

.AGUARDANDO_LIBERACAO_OPERADORA span {
  margin-right: 10px;
  min-width: 100%;
  margin-left: 56px;
}

.AGUARDANDO_DOCUMENTO {
  margin-right: 10px;
  width: 22px !Important;
  height: 22px !Important;
  min-width: 0px !Important;
  background: linear-gradient(#e6e6e6, #ffffff);
  border-color: #ffffff;
  border-radius: 50px;
}

.AGUARDANDO_DOCUMENTO span {
  margin-right: 10px;
  min-width: 100%;
  margin-left: 56px;
}

.DOCUMENTO_REJEITADO {
  margin-right: 10px;
  width: 22px !Important;
  height: 22px !Important;
  min-width: 0px !Important;
  background: linear-gradient(to right, #ff0000, #ffffff, #ff0000);
  border-color: #ff0000;
  border-radius: 50px;
}

.DOCUMENTO_REJEITADO span {
  margin-right: 10px;
  min-width: 100%;
  margin-left: 56px;
}

.AGUARDANDO_EC {
  margin-right: 10px;
  width: 22px !Important;
  height: 22px !Important;
  min-width: 0px !Important;
  background: linear-gradient(#ffffff, #804000);
  border-color: #804000;
  border-radius: 50px;
}

.AGUARDANDO_EC span {
  margin-right: 10px;
  min-width: 100%;
  margin-left: 56px;
}

.T {
  margin-right: 10px;
  width: 22px !Important;
  height: 22px !Important;
  min-width: 0px !Important;
  background: linear-gradient(#ffffff, #ff6600);
  border-color: #ff6600;
  border-radius: 50px;
}

.T span {
  margin-right: 10px;
  min-width: 100%;
  margin-left: 56px;
}

.P {
  margin-right: 10px;
  width: 22px !Important;
  height: 22px !Important;
  min-width: 0px !Important;
  background: linear-gradient(#ffffff, #003d99);
  border-color: #003d99;
  border-radius: 50px;
}

.P span {
  margin-right: 10px;
  min-width: 100%;
  margin-left: 56px;
}

.A,
.W,
.V,
.M {
  margin-right: 10px;
  width: 22px !Important;
  height: 22px !Important;
  min-width: 0px !Important;
  background: linear-gradient(#ffffff, #66c2ff);
  border-color: #66c2ff;
  border-radius: 50px;
}

.A span {
  margin-right: 10px;
  min-width: 100%;
  margin-left: 56px;
}

.W span {
  margin-right: 10px;
  min-width: 100%;
  margin-left: 56px;
}

.V span {
  margin-right: 10px;
  min-width: 100%;
  margin-left: 56px;
}

.M span {
  margin-right: 10px;
  min-width: 100%;
  margin-left: 56px;
}

.F,
.R,
.N {
  margin-right: 10px;
  width: 22px !Important;
  height: 22px !Important;
  min-width: 0px !Important;
  background: linear-gradient(#ffffff, #009900);
  border-color: #009900;
  border-radius: 50px;
}

.F span {
  margin-right: 10px;
  min-width: 100%;
  margin-left: 56px;
}

.R span {
  margin-right: 10px;
  min-width: 100%;
  margin-left: 56px;
}

.N span {
  margin-right: 10px;
  min-width: 100%;
  margin-left: 56px;
}

.SUSPENSO {
  margin-right: 10px;
  width: 22px !Important;
  height: 22px !Important;
  min-width: 0px !Important;
  background: linear-gradient(#ffffff, #e600e6);
  border-color: #e600e6;
  border-radius: 50px;
}

.SUSPENSO span {
  margin-right: 10px;
  min-width: 100%;
  margin-left: 56px;
}

.CANCELADO {
  margin-right: 10px;
  width: 22px !Important;
  height: 22px !Important;
  min-width: 0px !Important;
  background: linear-gradient(#ffffff, #808080);
  border-color: #808080;
  border-radius: 50px;
}

.CANCELADO span {
  margin-right: 10px;
  min-width: 100%;
  margin-left: 56px;
}

.C {
  margin-right: 10px;
  width: 22px !Important;
  height: 22px !Important;
  min-width: 0px !Important;
  background: linear-gradient(#e7e3e3, #577486);
  border-color: #808080;
  border-radius: 50px;
}

.C span {
  margin-right: 10px;
  min-width: 100%;
  margin-left: 56px;
}

.Z {
  margin-right: 10px;
  width: 22px !Important;
  height: 22px !Important;
  min-width: 0px !Important;
  background: linear-gradient(#ffffff, #ff0000);
  border-color: #ff0000;
  border-radius: 50px;
}

.Z span {
  margin-right: 10px;
  min-width: 100%;
  margin-left: 56px;
}

.NN {
  margin-right: 10px;
  width: 22px !Important;
  height: 22px !Important;
  min-width: 0px !Important;
  background: linear-gradient(#ffffff, #6600ff);
  border-color: #6600ff;
  border-radius: 50px;
}

.NN span {
  margin-right: 10px;
  min-width: 100%;
  margin-left: 56px;
}
