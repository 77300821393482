// Typography
$base-font-family: $font-stack-system;
$heading-font-family: $base-font-family;

// Line height
$base-line-height: 1.5;
$heading-line-height: 1.2;

// Other Sizes
$base-border-radius: 3px;
$base-spacing: 1.5em;
$big-spacing: $base-spacing * 2;
$small-spacing: $base-spacing / 2;
$base-z-index: 0;

// Colors
$white: #FFF;
$grey-light: #F5F5F5;
$grey: #E0E0E0;
$grey-dark: #424242;

// Font Colors
$base-font-color: $grey-dark;
$action-color: $grey;

// Border
$base-border-color: $grey;
$base-border: 1px solid $base-border-color;

// Background Colors
$viewport-background-color: #fff;

// Focus
$focus-outline-color: transparentize($action-color, 0.4);
$focus-outline-width: 3px;
$focus-outline: $focus-outline-width solid $focus-outline-color;
$focus-outline-offset: 2px;

// Animations
$base-duration: 150ms;
$base-timing: ease;
