/* CSS Document */
.myFaces_panelTabbedPane_activeHeaderCell tab input[type="submit"] {
  position: relative;
  color: #2b7bbc;
  border-width: 0px;
  text-transform: uppercase;
  font-size: 10px;
  font: 11px Verdana, Helvetica, sans-serif, FontAwesome;
  background-repeat: no-repeat;
}

body {
  font-size: 11px;
  font-family: verdana, arial, tahoma, FontAwesome;
  text-decoration: none;
  color: #2d5d8b; /*#2b7bbc;*/

  /*background-color: #FFFFFF;
	overflow-x:hidden;
 	scrollbar-shadow-color: #FFFFFF; 
 	scrollbar-face-color: #F3F3F3; 
 	scrollbar-3dlight-color: #FFFFFF; 
 	scrollbar-highlight-color: #FFFFFF; 
 	scrollbar-darkshadow-color: #CCCCCC; 
 	scrollbar-track-color: #F7F7F7; 
 	scrollbar-arrow-color: #894343;*/
}

#corpo {
  background-color: #e3e3e3;
  height: 100%;
  width: 995px;
  margin-left: 3px;

  /*border-radius: 4px 4px 4px 4px;    */
  box-shadow: 0 2px 5px #000000;
}

tbody {
  font-size: 11px;
  font-family: verdana, arial, tahoma, FontAwesome;
  text-decoration: none;
}

.esquecisenha {
  font-size: 8px;
  font-family: verdana, arial, tahoma, FontAwesome;
  text-decoration: none;
  color: #ffffff;
  cursor: pointer;
}

.listagem {
  font-family: Arial;
  font-size: 10px;
  color: #0000ff;
}
.tabela {
  border: 1px solid #0096bd;
  border-collapse: collapse;
}
table {
  text-align: left;
}
table th {
  text-align: center;
  background-color: #366aca;
  font-size: 11px;
  color: #ffffff;
  font-weight: bold;
}

table th a:link,
table th a:visited {
  /*color: white;*/
  font-weight: bold;
  text-decoration: none;
}
table th a:link:hover,
table th a:visited:hover {
  color: white;
  font-weight: bold;
  text-decoration: underline;
}
.tabela td {
  border: 1px solid #366aca;
  padding: 2px;
  color: #2b7bbc;
  font-family: verdana, arial, tahoma, FontAwesome;
  font-size: 11px;
}

ThemeOffice2003MenuItemLeft {
  color: #ffffff;

  font-size: 11px;
}

.cronometro.cronometro {
  font-family: verdana, arial, tahoma, FontAwesome;
  padding: 4px;
  font-size: 10px;
  text-align: left;
  color: #ffffff;
}
.tabelaResult {
  border-collapse: collapse;
  width: 85%;
}

.tabelaResult th {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #c0c0c0;
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: #c0c0c0;
  background-color: #d6e6fb;
  /* background-image: url(colHdrGrad.png); */
  background-position: top left;
  color: #0b356c;
  font-family: verdana, arial, tahoma, FontAwesome;
  font-size: 10px;
  font-weight: bold;
  padding: 2px;
  text-align: center;
}
.tabelaResult th a:link,
.tabelaResult th a:visited {
  color: #0b356c;
  font-weight: bold;
  text-decoration: none;
}

.tabelaResult tr {
  border: 1px solid #c0c0c0;
  padding: 2px;
}

.tabelaResult td {
  border: 1px solid #c0c0c0;
  padding: 2px;
  color: #444444;
}

.tabelaResult tr:hover {
  border: 1px solid #c0c0c0;
  padding: 2px;
  color: #444444;
  /*background-color: #C5C9FF;*/
  background-color: #93d2d9;
  font-family: verdana, arial, tahoma, FontAwesome;
  font-size: 10px;
}

.tabelaResult2 {
  border-collapse: collapse;
  width: 85%;
}

.tabelaResult2 th {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #c0c0c0;
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: #c0c0c0;
  background-color: #d6e6fb;
  /* background-image: url(colHdrGrad.png); */
  background-position: top left;
  color: #0b356c;
  font-family: verdana, arial, tahoma, FontAwesome;
  font-size: 10px;
  font-weight: bold;
  padding: 2px;
  text-align: center;
}
.tabelaResult2 th a:link,
.tabelaResult2 th a:visited {
  color: #0b356c;
  font-weight: bold;
  text-decoration: none;
}

.tabelaResult2 tr {
  border: 1px solid #c0c0c0;
  padding: 2px;
}

.tabelaResult2 td {
  border: 1px solid #c0c0c0;
  padding: 2px;
  color: #444444;
}

.tabelaResult2 tr:hover {
  border: 1px solid #c0c0c0;
  padding: 2px;
  color: #444444;
  /*background-color: #C5C9FF;*/
  background-color: #93d2d9;
  font-family: verdana, arial, tahoma, FontAwesome;
  font-size: 8px;
}

.tabelaConciliacao {
  border-collapse: collapse;
  width: 100%;
}

.tabelaConciliacao th {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #c0c0c0;
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: #c0c0c0;
  background-color: #d6e6fb;
  /* background-image: url(colHdrGrad.png); */
  background-position: top left;
  color: #0b356c;
  font-family: verdana, arial, tahoma, FontAwesome;
  font-size: 10px;
  font-weight: bold;
  padding: 2px;
  text-align: center;
}
.tabelaConciliacao th a:link,
.tabelaResult th a:visited {
  color: #0b356c;
  font-weight: bold;
  text-decoration: none;
}

.tabelaConciliacao tr {
  border: 1px solid #c0c0c0;
  padding: 2px;
}

.tabelaConciliacao td {
  border: 1px solid #c0c0c0;
  padding: 2px;
  color: #444444;
  font-size: 9px;
  text-align: right;
}

.tabelaConciliacao tr:hover {
  border: 1px solid #c0c0c0;
  padding: 2px;
  color: #444444;
  /*background-color: #C5C9FF;*/
  background-color: #93d2d9;
  font-family: verdana, arial, tahoma, FontAwesome;
  font-size: 10px;
}

.textConciliacao {
  font-size: 10px;
  font-family: verdana, arial, tahoma, FontAwesome;
  text-decoration: none;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
}
.textConciliacaoMenor {
  font-size: 8px;
  font-family: verdana, arial, tahoma, FontAwesome;
  text-decoration: none;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
}

.primeiro2 {
  /* background-color: #93D2D9 ;*/
  background-color: #d9d9d9;
  font-family: verdana, arial, tahoma, FontAwesome;
  font-size: 8px;
}

/* Definição de estilos para coluna */

.ultimo2 {
  background-color: #ffffff;
  font-family: verdana, arial, tahoma, FontAwesome;
  font-size: 8px;
}

.primeiro {
  /* background-color: #93D2D9 ;*/
  background-color: #d9d9d9;
  font-family: verdana, arial, tahoma, FontAwesome;
  font-size: 10px;
}

/* Definição de estilos para coluna */

.ultimo {
  background-color: #ffffff;
  font-family: verdana, arial, tahoma, FontAwesome;
  font-size: 10px;
}

.paginacao {
  font-family: Arial;
  font-size: 10px;
  color: #880000;
  text-decoration: none;
}
.pag {
  font-family: Arial;
  font-size: 10px;
  color: #000088;
  text-decoration: none;
  font-weight: bold;
}

font {
  font-family: verdana, arial, tahoma, FontAwesome;
  font-size: 11px;
}
legend {
  font-family: verdana, arial, tahoma, FontAwesome;
  font-size: 11px;
  color: #000000;
  text-transform: uppercase;
  font-weight: bold;
}

.cabecalhoLogin {
  font-size: 11px;
  font-family: verdana, arial, tahoma, FontAwesome;
  color: white;
}

input.conciliacao {
  /* background-image: url(bg_input_text_conciliacao.png); */
  background-repeat: no-repeat;
  border-width: 0px;
  background-color: transparent;
  padding: 1px;
  padding-left: 4px;

  width: 71px;
  height: 10px;
  font-family: verdana, arial, tahoma, FontAwesome;
  font-size: 8px;
  color: #2b7bbc;
  text-transform: uppercase;
}

input.login {
  background-image: url(bg_input_text_login.png);
  background-repeat: no-repeat;
  border-width: 0px;
  background-color: transparent;
  padding: 4px;

  width: 191px;
  height: 19px;
  font-family: verdana, arial, tahoma, FontAwesome;
  font-size: 11px;
  color: #2b7bbc;
  text-transform: uppercase;
}

input.captcha {
  background-image: url(bg_input_text_login.png);
  background-repeat: no-repeat;
  border-width: 0px;
  background-color: transparent;
  padding: 4px;

  width: 191px;
  height: 19px;
  font-family: verdana, arial, tahoma, FontAwesome;
  font-size: 11px;
  color: #2b7bbc;
}

input.text {
  /* background-image: url(bg_input_text_1.png); */
  background-repeat: no-repeat;
  border-width: 0px;
  background-color: transparent;
  padding: 4px;

  width: 297px;
  height: 19px;
  font-family: verdana, arial, tahoma, FontAwesome;
  font-size: 11px;
  color: #2b7bbc;
  text-transform: uppercase;
}

input.textautocomplete {
  /* background-image: url(bg_input_text_autocomplete.png); */
  background-repeat: no-repeat;
  border-width: 0px;
  background-color: transparent;
  padding: 4px;

  width: 297px;
  height: 19px;
  font-family: verdana, arial, tahoma, FontAwesome;
  font-size: 11px;
  color: #2b7bbc;
  text-transform: uppercase;
}

input.textnormal {
  /* background-image: url(bg_input_text_1.png); */
  background-repeat: no-repeat;
  border-width: 0px;
  background-color: transparent;
  padding: 4px;

  width: 297px;
  height: 19px;
  font-family: verdana, arial, tahoma, FontAwesome;
  font-size: 11px;
  color: #2b7bbc;
}

textarea {
  /* background-image: url(bg_input_textarea_conciliacao.png); */
  background-repeat: no-repeat;
  border-width: 0px;
  background-color: transparent;
  width: 320px;
  height: 177px;
  padding: 2px;
  font-family: verdana, arial, tahoma, FontAwesome;
  font-size: 11px;
  color: #2b7bbc;
  /*text-transform:uppercase;*/
  overflow-y: scroll;
}

.textarea_extendido {
  width: 800px;
  height: 80px;
  background-color: white;
  background-image: none;
}

textarea.nota {
  /* background-image: url(bg_input_textarea_conciliacao_nota.png); */
  background-repeat: no-repeat;
  border-width: 0px;
  background-color: transparent;
  width: 720px;
  height: 407px;
  padding: 2px;
  font-family: verdana, arial, tahoma, FontAwesome;
  font-size: 11px;
  color: #2b7bbc;
  /*text-transform:uppercase;*/
  overflow-y: scroll;
}

input.select {
  /* background-image: url(bg_input_text_1.png); */
  background-repeat: no-repeat;
  border-width: 0px;
  background-color: transparent;
  padding: 4px;

  width: 306px;
  height: 25px;
  font-family: verdana, arial, tahoma, FontAwesome;
  font-size: 11px;
  color: #2b7bbc;
  text-transform: uppercase;
}

input.calendar {
  /* background-image: url(bg_input_text_calendar.png); */
  background-repeat: no-repeat;
  border-width: 0px;
  background-color: transparent;
  padding: 4px;

  width: 126px;
  height: 19px;
  font-family: verdana, arial, tahoma, FontAwesome;
  font-size: 11px;
  color: #2b7bbc;
  text-transform: uppercase;
}

input {
  background-color: #fbfbfb;
  border-color: #56879b;
  border-right: #56879b 1pt solid;
  border-top: #56879b 1pt solid;
  border-left: #56879b 1pt solid;
  border-bottom: #56879b 1pt solid;
  font-family: verdana, arial, tahoma, FontAwesome;
  font-size: 11px;
  color: #2b7bbc;
}

input.botaoConheca {
  width: 194px;
  height: 30px;
  /* background-image: url(CONHECA.png); */
  /*background-color:#cecece;*/
  background-color: transparent;
  border-width: 0px;
  background-repeat: no-repeat;

  font-size: 11px;
  color: #2b7bbc;
  text-transform: uppercase;
}

input.botao {
  position: relative;
  color: #2b7bbc;
  border-width: 0px;
  text-transform: uppercase;
  font-size: 10px;
  font: 11px Verdana, Helvetica, sans-serif, FontAwesome;
  background-repeat: no-repeat;

  padding: 10px 10px;
  border-radius: 5px;
  min-width: 100px;
  background: #ffffff; /* Old browsers */
  background: -moz-linear-gradient(top, #ffffff 0%, #b3b3b3 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #ffffff 0%, #b3b3b3 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #ffffff 0%, #b3b3b3 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#b3b3b3',GradientType=0 ); /* IE6-9 */
  /*font-weight: 600;*/
  margin: 0px 7px;
  margin-bottom: 3px;
  margin-top: 5px;
}

.botaoUpload {
  position: relative;
  color: #2b7bbc;
  text-transform: uppercase;
  width: 200px;
  font-size: 10px;
  font: 11px Verdana, Helvetica, sans-serif, FontAwesome;
  background-repeat: no-repeat;

  padding: 10px 10px;
  border-radius: 5px;
  background: #ffffff; /* Old browsers */
  background: -moz-linear-gradient(top, #ffffff 0%, #b3b3b3 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #ffffff 0%, #b3b3b3 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #ffffff 0%, #b3b3b3 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#b3b3b3',GradientType=0 ); /* IE6-9 */

  margin: 0px 7px;
  margin-bottom: 3px;
  margin-top: 5px;
}

input[type="submit"] {
  position: relative;
  color: #2b7bbc;
  border-width: 0px;
  text-transform: uppercase;
  font-size: 10px;
  font: 11px Verdana, Helvetica, sans-serif, FontAwesome;
  background-repeat: no-repeat;

  padding: 10px 10px;
  border-radius: 5px;
  min-width: 100px;
  background: #ffffff; /* Old browsers */
  background: -moz-linear-gradient(top, #ffffff 0%, #b3b3b3 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #ffffff 0%, #b3b3b3 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #ffffff 0%, #b3b3b3 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#b3b3b3',GradientType=0 ); /* IE6-9 */
  /*font-weight: 600;*/
  margin: 0px 7px;
  margin-bottom: 3px;
  margin-top: 5px;
}

input[type="submit"]:hover {
  opacity: 0.5;
}

.botaoSpc {
  width: 209px;
  height: 28px;
  background-image: url(bt_entrar.jpg);
  /*background-color:#cecece;*/
  background-color: transparent;
  border-width: 0px;
  background-repeat: no-repeat;

  font-size: 10px;
  color: #2b7bbc;
  text-transform: uppercase;
}

input.botao2 {
  width: 232px;
  height: 30px;
  /* background-image: url(button_off2.png); */
  /*background-color:#cecece;*/
  background-color: transparent;
  border-width: 0px;
  background-repeat: no-repeat;

  font-size: 10px;
  color: #2b7bbc;
  text-transform: uppercase;
}

input.botao2:hover {
  width: 232px;
  height: 30px;
  /* background-image: url(button_on2.png); */
  background-color: transparent;
  border-width: 0px;
  background-repeat: no-repeat;

  font-size: 10px;
  color: #2b7bbc;
  text-transform: uppercase;
}

.pintar {
  background-color: #cecece;
  color: #2b7bbc;
}

select {
  /* background-image: url(bg_input_text_1.png); */
  background-repeat: no-repeat;
  border-width: 0px;
  background-color: transparent;

  width: 307px;
  height: 26px;
  font-family: verdana, arial, tahoma, FontAwesome;
  font-size: 13px;
  color: #2b7bbc;
  text-transform: uppercase;
}

select.textarea {
  /* background-image: url(bg_input_textarea_conciliacao.png); */
  background-repeat: no-repeat;
  border-width: 0px;
  background-color: transparent;
  width: 320px;
  height: 177px;
  padding: 2px;
  font-family: verdana, arial, tahoma, FontAwesome;
  font-size: 11px;
  color: #2b7bbc;
  /*text-transform:uppercase;*/
  overflow-y: scroll;
}
/*select{
    background-color:#FBFBFB;
    border-color:#56879B;
    BORDER-RIGHT: #56879B 1pt solid;
    BORDER-TOP: #56879B 1pt solid;
    BORDER-LEFT: #56879B 1pt solid;
    BORDER-BOTTOM: #56879B 1pt solid;
    font-family:verdana, arial, tahoma,FontAwesome;
    font-size:11px;
    color: #2b7bbc;
    text-transform:uppercase;

}*/

span.bemvindo {
  color: #ffffff;
  font-family: verdana, arial, tahoma, FontAwesome;
  font-size: 7px;
}

td.login {
  color: #273e72;
  font-family: verdana, arial, tahoma, FontAwesome;
  font-size: 11px;
}
/*textarea{
    background-color:#FFFFFF;
    border-color:#445581;
    BORDER-RIGHT: #445581 1pt solid;
    BORDER-TOP: #445581 1pt solid;
    BORDER-LEFT: #445581 1pt solid;
    BORDER-BOTTOM: #445581 1pt solid;
    font-family:verdana, arial, tahoma,FontAwesome;
    font-size:11px;
    /*text-transform:uppercase;*/
/*}
/*.uppercase {text-transform:uppercase}*/

font.obrig {
  color: #ff0000;
  font-size: 10px;
}

a.voltar {
  color: #333333;
  text-decoration: none;
}
a:hover {
  color: #6699ff;
  text-decoration: none;
}
a {
  text-decoration: none;
  border: 0px;
  color: #000000;
}
.marcado {
  font: bold 11px Verdana, Helvetica, sans-serif;
  color: #ff0000;
  font-variant: small-caps;
  background: #efefef;
  text-align: center;
  text-shadow: Silver;
}

.barNav a {
  font: bold 11px Verdana, Helvetica, sans-serif;
  color: #000088;
  text-decoration: none;
  font-variant: small-caps;
  background: #efefef;
  text-align: center;
  padding: 1px 5px;
  margin-right: 1px;
  border: 1px solid #dddddd;
  text-shadow: Silver;
}

.barNav a:hover {
  color: #666666;
  background: #fcfcfc;
  border: 1px solid #cccccc;
}
.titulo {
  font: bold 12px Arial;
  color: #ffffff;
  text-decoration: none;
  font-weight: bold;
  background-color: #0096bd;
}
.tituloTop {
  font: bold 12px Arial;
  color: #4a597b;
  text-decoration: none;
  font-weight: bold;
}
.pesquisa {
  font: 11px Arial;
  color: #2b7bbc;
  text-decoration: none;
}

.button {
  font: bold 12px/24px arial, helvetica, sans-aerif;
  padding: 0px;
  text-decoration: none;
  text-align: center;
  color: #ffffff;
  /* background: #666 url("./imagens/botao_liso.gif") no-repeat center center; */
  border: 0;
  width: 78px;
  height: 30px;
  display: block;
}
.button:hover {
  /* background: #666 url("./imagens/botao_liso2.gif") no-repeat center center; */
  color: #cccccc;
  width: 78px;
  height: 30px;
}
.1 {
  background-color: #ffffff;
}
.2 {
  background-color: #cccccc;
}

#progreso {
  /* background: url(textarea.png) no-repeat; */
  background-position: -300px 0px;
  width: 300px;
  height: 14px;
  text-align: center;
  color: #000000;
  font-size: 8pt;
  font-family: Arial;
  text-transform: uppercase;
}

select.textERRO {
  /* background-image: url(bg_input_text_ERRO.png); */
  background-repeat: no-repeat;
  border-width: 0px;
  background-color: transparent;
  width: 307px;
  height: 26px;
  font-family: verdana, arial, tahoma, FontAwesome;
  font-size: 13px;
  color: #2b7bbc;
  text-transform: uppercase;
}

input.textERRO {
  /* background-image: url(bg_input_text_ERRO.png); */
  background-repeat: no-repeat;
  border-width: 0px;
  background-color: transparent;
  padding: 4px;
  width: 297px;
  height: 19px;
  font-family: verdana, arial, tahoma, FontAwesome;
  font-size: 11px;
  color: #2b7bbc;
  text-transform: uppercase;
}
.campoProtegido {
  /* background-image: url(bg_input_text_1.png); */
  background-repeat: no-repeat;
  border-width: 0px;
  background-color: transparent;
  padding: 4px;
  width: 297px;
  height: 19px;
  font-family: verdana, arial, tahoma, FontAwesome;
  font-size: 11px;
  color: #2b7bbc;
  text-transform: uppercase;
}

input.textERROPequeno {
  /* background-image: url(bg_input_text_ERRO.png); */
  background-repeat: no-repeat;
  border-width: 0px;
  background-color: transparent;
  padding: 4px;
  width: 90px;
  height: 19px;
  font-family: verdana, arial, tahoma, FontAwesome;
  font-size: 11px;
  color: #2b7bbc;
  text-transform: uppercase;
}

.hasDatepicker {
  /* background-image: url(bg_input_text_1.png); */
  background-repeat: no-repeat;
  border-width: 0px;
  background-color: transparent;
  padding: 4px;

  width: 90px;
  height: 19px;
  font-family: verdana, arial, tahoma, FontAwesome;
  font-size: 11px;
  color: #2b7bbc;
}

#conteudo {
  background-color: #f2f2f2;
  height: 63px;
  width: 267px;
  border-radius: 4px 4px 4px 4px;
  box-shadow: 0 2px 10px #000000;
}

.hasDateNormal {
  /* background-image: url(bg_input_text_1.png); */
  background-repeat: no-repeat;
  border-width: 0px;
  background-color: transparent;
  padding: 4px;

  width: 90px;
  height: 19px;
  font-family: verdana, arial, tahoma, FontAwesome;
  font-size: 11px;
  color: #2b7bbc;
}

.tabelaNormal {
  border-collapse: collapse;
  width: 85%;
}

.tabelaNormal th {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #c0c0c0;
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: #c0c0c0;
  background-color: #d6e6fb;
  /* background-image: url(colHdrGrad.png); */
  background-position: top left;
  color: #0b356c;
  font-family: verdana, arial, tahoma, FontAwesome;
  font-size: 10px;
  font-weight: bold;
  padding: 2px;
  text-align: center;
}

.tabelaNormal tr {
  border: 1px solid #c0c0c0;
  padding: 2px;
}

.tabelaNormal td {
  border: 1px solid #c0c0c0;
  padding: 2px;
  color: #444444;
}

input.textPequeno {
  /* background-image: url(bg_input_text_1.png); */
  background-repeat: no-repeat;
  border-width: 0px;
  background-color: transparent;
  padding: 4px;

  width: 60px;
  height: 19px;
  font-family: verdana, arial, tahoma, FontAwesome;
  font-size: 11px;
  color: #2b7bbc;
  text-transform: uppercase;
}

input.textMedio {
  /* background-image: url(bg_input_text_1.png); */
  background-repeat: no-repeat;
  border-width: 0px;
  background-color: transparent;
  padding: 4px;

  width: 150px;
  height: 19px;
  font-family: verdana, arial, tahoma, FontAwesome;
  font-size: 11px;
  color: #2b7bbc;
  text-transform: uppercase;
}

input.textMini {
  /* background-image: url(bg_input_text_1.png); */
  background-repeat: no-repeat;
  border-width: 0px;
  background-color: transparent;
  padding: 4px;

  width: 20px;
  height: 19px;
  font-family: verdana, arial, tahoma, FontAwesome;
  font-size: 11px;
  color: #2b7bbc;
  text-transform: uppercase;
}

input.textData {
  /* background-image: url(bg_input_text_1.png); */
  background-repeat: no-repeat;
  border-width: 0px;
  background-color: transparent;
  padding: 4px;

  width: 70px;
  height: 19px;
  font-family: verdana, arial, tahoma, FontAwesome;
  font-size: 11px;
  color: #2b7bbc;
  text-transform: uppercase;
}

selectMedio {
  /* background-image: url(bg_input_text_1.png); */
  background-repeat: no-repeat;
  border-width: 0px;
  background-color: transparent;

  width: 307px;
  height: 26px;
  font-family: verdana, arial, tahoma, FontAwesome;
  font-size: 13px;
  color: #2b7bbc;
  text-transform: uppercase;
}

.campoProtegidoMini {
  /* background-image: url(bg_input_text_1.png); */
  background-repeat: no-repeat;
  border-width: 0px;
  background-color: transparent;
  padding: 4px;
  width: 20px;
  height: 19px;
  font-family: verdana, arial, tahoma, FontAwesome;
  font-size: 11px;
  color: #2b7bbc;
  text-transform: uppercase;
}

.campoProtegidoMedio {
  /* background-image: url(bg_input_text_1.png); */
  background-repeat: no-repeat;
  border-width: 0px;
  background-color: transparent;
  padding: 4px;
  width: 150px;
  height: 19px;
  font-family: verdana, arial, tahoma, FontAwesome;
  font-size: 11px;
  color: #2b7bbc;
  text-transform: uppercase;
}

input.calendarMenor {
  /* background-image: url(bg_input_text_calendarMenor.png); */
  background-repeat: no-repeat;
  border-width: 0px;
  background-color: transparent;
  padding: 4px;

  width: 77px;
  height: 19px;
  font-family: verdana, arial, tahoma, FontAwesome;
  font-size: 11px;
  color: #2b7bbc;
  text-transform: uppercase;
}

input.textLivre {
  /* background-image: url(bg_input_text_1.png); */
  background-repeat: no-repeat;
  border-width: 0px;
  background-color: transparent;
  padding: 4px;

  width: 297px;
  height: 19px;
  font-family: verdana, arial, tahoma, FontAwesome;
  font-size: 11px;
  color: #2b7bbc;
}

#PROGRESSO {
  background-color: #ffffff;
  border: solid #808080 2px;
  border-radius: 5px;
  /* background: url(textarea.png) no-repeat; */
  width: 140px;
  height: 11px;
  text-align: center;
  color: #000000;
  font-size: 8pt;
  font-family: Arial;
  text-transform: uppercase;
  box-shadow: 3px 3px 3px #c0c0c0;
  padding: 0px;
}

span.fa {
  margin-right: 3px;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.tab {
  border: 100px;
  background-image: none;
  background-color: #b3b3b3;
  border-bottom-color: 1px solid blue;
  border-radius: 5px 5px 0px 0px;
}

.tab td.myFaces_panelTabbedPane_activeHeaderCell input {
  background: none;
  width: inherit;
}

.tab td.myFaces_panelTabbedPane_activeHeaderCell input {
  background: none;
  width: inherit;
}

.tab td.myFaces_panelTabbedPane_subHeaderCell {
  background-color: none;
}

.myFaces_panelTabbedPane_activeHeaderCell input,
.myFaces_panelTabbedPane_inactiveHeaderCell input {
  position: relative;
  color: #2b7bbc;
  border-width: 0px;
  text-transform: uppercase;
  font-size: 10px;
  font: 11px Verdana, Helvetica, sans-serif, FontAwesome;
  background-repeat: no-repeat;

  padding: 10px 10px;
  border-radius: 5px;
  min-width: 100px;
  background: #ffffff; /* Old browsers */
  background: -moz-linear-gradient(top, #ffffff 0%, #b3b3b3 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #ffffff 0%, #b3b3b3 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #ffffff 0%, #b3b3b3 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#b3b3b3',GradientType=0 ); /* IE6-9 */
  /*font-weight: 600;*/
  margin: 0px 0px;
  margin-bottom: 0px;
  margin-top: 0px;
}

.myFaces_panelTabbedPane_activeHeaderCell input:hover,
.myFaces_panelTabbedPane_inactiveHeaderCell input:hover {
  position: relative;
  color: #2b7bbc;
  border-width: 0px;
  text-transform: uppercase;
  font-size: 10px;
  font: 11px Verdana, Helvetica, sans-serif, FontAwesome;
  background-repeat: no-repeat;

  padding: 10px 10px;
  border-radius: 5px;
  min-width: 100px;
  background: #ffffff; /* Old browsers */
  background: -moz-linear-gradient(top, #ffffff 0%, #b3b3b3 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #ffffff 0%, #b3b3b3 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #ffffff 0%, #b3b3b3 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#b3b3b3',GradientType=0 ); /* IE6-9 */
  /*font-weight: 600;*/
  margin: 0px 0px;
  margin-bottom: 0px;
  margin-top: 0px;
}

#editaChamadosForm__id51 .myFaces_panelTabbedPane_activeHeaderCell input,
#editaChamadosForm__id51 .myFaces_panelTabbedPane_pane,
#editaChamadosForm__id51 .myFaces_panelTabbedPane_subHeaderCell {
  background-color: transparent;
}

#editaChamadosForm__id55 .myFaces_panelTabbedPane_activeHeaderCell input,
#editaChamadosForm__id55 .myFaces_panelTabbedPane_pane,
#editaChamadosForm__id55 .myFaces_panelTabbedPane_subHeaderCell {
  background-color: transparent;
}

#editaChamadosForm__id355 .myFaces_panelTabbedPane_activeHeaderCell input,
#editaChamadosForm__id355 .myFaces_panelTabbedPane_pane,
#editaChamadosForm__id355 .myFaces_panelTabbedPane_subHeaderCell {
  background-color: transparent;
}

#editaChamadosForm__id57 .myFaces_panelTabbedPane_activeHeaderCell input,
#editaChamadosForm__id57 .myFaces_panelTabbedPane_pane,
#editaChamadosForm__id57 .myFaces_panelTabbedPane_subHeaderCell {
  background-color: transparent;
}

#editaChamadosForm__id59 .myFaces_panelTabbedPane_activeHeaderCell input,
#editaChamadosForm__id59 .myFaces_panelTabbedPane_pane,
#editaChamadosForm__id59 .myFaces_panelTabbedPane_subHeaderCell {
  background-color: transparent;
}

#editaChamadosForm__id54 .myFaces_panelTabbedPane_activeHeaderCell input,
#editaChamadosForm__id54 .myFaces_panelTabbedPane_pane,
#editaChamadosForm__id54 .myFaces_panelTabbedPane_subHeaderCell {
  background-color: transparent;
}

#editaChamadosForm__id58 .myFaces_panelTabbedPane_activeHeaderCell input,
#editaChamadosForm__id58 .myFaces_panelTabbedPane_pane,
#editaChamadosForm__id58 .myFaces_panelTabbedPane_subHeaderCell {
  background-color: transparent;
}

#editaChamadosForm__id60 .myFaces_panelTabbedPane_activeHeaderCell input,
#editaChamadosForm__id60 .myFaces_panelTabbedPane_pane,
#editaChamadosForm__id60 .myFaces_panelTabbedPane_subHeaderCell {
  background-color: transparent;
}

#editaChamadosForm__id61 .myFaces_panelTabbedPane_activeHeaderCell input,
#editaChamadosForm__id61 .myFaces_panelTabbedPane_pane,
#editaChamadosForm__id61 .myFaces_panelTabbedPane_subHeaderCell {
  background-color: transparent;
}

#editaChamadosForm__id63 .myFaces_panelTabbedPane_activeHeaderCell input,
#editaChamadosForm__id63 .myFaces_panelTabbedPane_pane,
#editaChamadosForm__id63 .myFaces_panelTabbedPane_subHeaderCell {
  background-color: transparent;
}

#editaChamadosForm__id62 .myFaces_panelTabbedPane_activeHeaderCell input,
#editaChamadosForm__id62 .myFaces_panelTabbedPane_pane,
#editaChamadosForm__id62 .myFaces_panelTabbedPane_subHeaderCell {
  background-color: transparent;
}

tr {
  border: none !important;
}
