.card {
  margin: 10px;
  padding: 15px;
}

.title {
  padding: 15px;
  text-align: center;
}

.refresh {
  padding: 0px !important;
  font-size: 5px;
  cursor: pointer;
  color: darkcyan;
  text-align: end;
}

.button {
  font-size: 11px !important;
  padding: 5px 9px !important;
}

.button100 {
  font-size: 11px !important;
  padding: 5px 9px !important;
  width: 100%;
}

.textarea {
  width: 100%;
}

.rem66 {
  font-size: 0.66rem;
}

.rem81 {
  font-size: 0.81rem;
}

.rem1 {
  font-size: 1rem;
}
