.topbar_logo {
  width: 169px !important;
  margin-top: 25px;
  margin-right: 0px !important;
}

.avatar-size {
  width: 50px;
  height: 50px;
}

.margin-user {
  margin-top: 25px;
}

.titulo-user {
  font-size: 10px !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  margin-top: 5px !important;
  margin-bottom: 0px !important;
}

.profile-space {
  padding-top: 40px !important;
}
