.LoginForm_logo {
  height: 100px;
  width: 228px;
}

.logo-conciliador {
  height: 54px;
  width: 237px;
}

.recaptcha-container {
  display: flex;
  justify-content: center;
}
